.card{
    height:170px;
    display: flex;
  
    justify-content:center;
    align-items:center;
    gap:5px;
    background:none;
    border:none;
    cursor: pointer;
}
.card:hover{
    background:rgb(252, 252, 252);
    box-shadow: 1px 1px 3px rgb(240, 240, 240);
}

.HomePageContent{
    display: flex;
    flex-direction: column;
    
}
.cardContent{
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    align-items: flex-start;
    padding:20px;
    gap:15px;

}
.search{
    height:40px;
    background:white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search input{
    height:20px;
    border:1px solid rgb(195, 194, 194);
    border:none;
    border-bottom:1px solid rgb(195, 194, 194);
    border-left: none;
    width:260px;
    height:30px;
    outline: none;
}
.search button{
    height:24px;
    border:none;
    background:rgb(246, 246, 246);
    border:1px solid rgb(195, 194, 194);
    border:none;
    height:35px;
    width:50px;
}
.search input:focus{
outline: nnone;
}
.image{
    background:rgb(248, 248, 248);
    height:155px;
    width:130px;
    border-radius: 10px;
}
.contestantDetails{

    width:180px;
    height:35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
}
.rightContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.votes{
    width:100%;
height:70px;
padding-top:10px;
font-family: 'Times New Roman', Times, serif;
font-size: 14px;
text-align: left;
color:rgb(124, 144, 151);

}
.action{
display: flex;
justify-content: flex-end;
   
    width:100%;
}
.action span{
    padding-left: 15px;
    padding-right:15px;
    border:none;
    background:none;
    color:rgb(36, 134, 36);
    cursor: pointer;
}
.nickname{
background: rgb(234, 234, 234,0.3);
font-family: 'Times New Roman', Times, serif;
font-size: 14px;
text-align: left;
color:rgb(40, 142, 179);
}
.code{
background: rgb(240, 239, 239,0.3);
font-family: 'Times New Roman', Times, serif;
font-size: 12px;
text-align: left;
color:rgb(40, 142, 179);
}

@media only screen and (max-width: 768px) {
    .search{
        height:40px;
        background:white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 0px 3px #f8f8f8;
        height: 60px;
    }
    .search input{
        height:30px;
   border:none;
   border-bottom:1px solid rgb(195, 194, 194);
   
        width:260px;
 
    }
    .search button{
        height:35px;
        width:50px;
        border:none;
        background:rgb(250, 250, 250);
        border:1px solid rgb(195, 194, 194);
        border:none;
    }
    .image{
        background:rgb(248, 248, 248);
     
        height:130px;
        width:130px;
        border-radius: 10px;
    }
    .contestantDetails{
        width:180px;
        height:35px;
        display: flex;
        flex-direction: column;
        
    }
    
    .votes{
        padding-top:20px;
        height:50px;
    }
    .card{
        width:100%;
        height: 140px;
    }
    .cardContent{
        justify-content: center;
    }
  }