.spinner{
    background:rgba(100, 100, 100, 0.1);
    height:inherit;
    width:inherit;
position:absolute;
display: flex;
justify-content: center;
align-items: center;
z-index: 10;
margin:auto;
}