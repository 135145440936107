.App {
  text-align: center;
  height: 100vh;
  width:100vw;
}
.header{
  /* padding:20px; */

  width:100%;
  background:white;
  color:#c1c3d1;
  font-weight: 700;
  font-size: 25px;;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;
}
.event{
  font-size: 25px;
}

