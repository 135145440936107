.card{
    height:400px;
    width:250px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:center;
    gap:10px;
}

.HomePageContent{
    display: flex;
    flex-direction: column;
    
}
.cardContent{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    padding:20px;
    gap:15px;
}

.search input:focus{
outline: nnone;
}
.image{
    background:rgb(248, 248, 248);
    border:1px solid rgb(203, 196, 196);
    height:200px;
    width:170px;
    border-radius: 10px;
}
.contestantDetails{
  
    width:200px;
  
    display: flex;
    flex-direction: column;

    
}
.nickname{
background: rgb(243, 243, 243);
font-size: 14px;

}
.input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
}
.inputBody{
    width:250px;

    padding-top:10px;
}
label{
    font-size: 14px;
    color:rgb(21, 21, 172);
}
.inputBody input{
    width:97%;
    height:35px;
    border: 1px solid rgb(169, 167, 167);
    font-size:20px;

}

.inputBody label{
    font-size:20px;
    color:rgb(47, 118, 144);
    font-family: 'Times New Roman', Times, serif;
}
.input button{
    font-family: 'Times New Roman', Times, serif;
    padding:10px;
    width:150px;
    cursor: pointer;
    background:rgb(43, 43, 108);
    border:none;
    color:white;
    font-size: 20px;;

}
.input button:hover{
    background:rgb(43, 43, 108,0.9); 
}
.nickname{
    background: rgba(255, 255, 255, 0.3);
    font-family: 'Times New Roman', Times, serif;
    font-size: 26px;
    text-align: center;
    color:blue;

    }
    .code{
    background: rgba(248, 248, 248, 0.3);
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    text-align: center;
    color:rgb(129, 134, 136);
    }

    .iframeContent{
        height:77vh;
        width:360px;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        gap:10px;
        border:none;
        background:transparent;
    }

    .goBack{
        color:rgb(255, 89, 0);
        font-family: 'Times New Roman', Times, serif;
        padding-top: 20px;
        border:none;
        background:transparent;
        height:3vh;
    }
 .iframeCard{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100%;
    background:transparent;
    border:none;
 }
  

@media only screen and (max-width: 768px) {

    .iframeContent{
        width:100%;
 
       
        height: 76vh;
    }

    .goBack{
        font-size: 18px;
    }
    .search{
        height:40px;
        background:white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 0px 3px #f8f8f8;
        height: 60px;
    }
    .search input{
        height:30px;
   border:none;
   border-bottom:1px solid rgb(195, 194, 194);
   
        width:260px;
 
    }
    .search button{
        height:35px;
        width:50px;
        border:none;
        background:rgb(250, 250, 250);
        border:1px solid rgb(195, 194, 194);
        border:none;
    }
    .cardContent{
        justify-content: center;
    }
  }