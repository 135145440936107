.card{
    height:200px;
    width:145px;
    background:rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:center;
    gap:5px;
cursor: pointer;
border:none;
border-radius: 5px;
box-shadow: 0px 0px 2px 0px rgba(206, 205, 205, 0.5);
}

.card:hover{
    box-shadow: 0px 8px 16px 0px rgba(206, 205, 205, 0.2);
    background:rgb(251, 251, 251);
}

.HomePageContent{
    display: flex;
    flex-direction: column;
    
}
.cardContent{
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    padding:20px;
    gap:15px;
}
.search{
    height:40px;
    background:white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search input{
    height:20px;
    border:1px solid rgb(195, 194, 194);
    border:none;
    border-bottom:1px solid rgb(195, 194, 194);
    border-left: none;
    width:260px;
    height:30px;
    outline: none;
    font-size:15px;
}
.search button{
    height:24px;
    border:none;
    background:rgb(246, 246, 246);
    border:1px solid rgb(195, 194, 194);
    border:none;
    height:35px;
    width:50px;
}
.search input:focus{
outline: nnone;
}
.image{
    background:rgb(248, 248, 248,0.3);
    border:1px solid rgb(203, 196, 196,0.2);
    height:155px;
    width:130px;
    border-radius: 8px;
}
.contestantDetails{
 
    width:130px;
    height:35px;
    display: flex;
    flex-direction: column;
    
}
.nickname{
/* background: rgb(234, 234, 234); */
font-size: 14px;
color:blue;
}
.code{
/* background: rgb(240, 239, 239); */
font-size: 14px;
color:grey;
}

@media only screen and (max-width: 768px) {
    .search{
        height:45px;
        background:white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 0px 3px #f8f8f8;
        height: 60px;
    }
    .search input{
        height:35px;
   border:none;
   border-bottom:1px solid rgb(195, 194, 194);
   
        width:260px;
 
    }
    .search button{
        height:40px;
        width:50px;
        border:none;
        background:rgb(250, 250, 250);
        border:1px solid rgb(195, 194, 194);
        border:none;
    }
    .cardContent{
        justify-content: center;
    }
  }