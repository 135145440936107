.container{

    height: 100%;
    width:100%;
    background:rgb(238, 242, 251,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .button{
border:none;
padding-left:10px;
padding-right: 10px;
cursor: pointer;
text-decoration: none;
padding-top:auto;
padding-bottom: auto;
color:black;
color:rgb(57, 57, 202)

}
.container .button:hover{
    color:rgb(57, 57, 202)
}
.container .button:active{
    color:rgb(57, 57, 202)
}
.container .button:focus{
    color:blue
}

.container {
    height:5vh;

  }
  .pageContent{
  height:84vh;
  overflow-y: auto;
  }